
import { GlossaryItemCode, IGlossaryItem } from '@/common/types';
import { Vue, Component, Prop } from 'vue-property-decorator'
import $app from '@/plugins/modules'

@Component
export default class GlossaryItem extends Vue {
  @Prop({ default: () => new Date() })
  readonly value: GlossaryItemCode | undefined;

  @Prop({ default: () => undefined })
  readonly glossary: string | undefined;

  @Prop({ default: () => undefined })
  // MODES: chip (default) , icon
  readonly mode: string | undefined;

  @Prop({ default: () => false })
  readonly xSmall: boolean | undefined;

  @Prop({ default: () => false })
  readonly small: boolean | undefined;

  @Prop({ default: () => false })
  readonly large: boolean | undefined;

  @Prop({ default: () => false })
  readonly xLarge: boolean | undefined;

  @Prop({ default: () => false })
  readonly left: boolean | undefined;

  @Prop({ default: () => false })
  readonly right: boolean | undefined;

  @Prop({ default: () => false })
  readonly top: boolean | undefined;

  @Prop({ default: () => false })
  readonly bottom: boolean | undefined;

  get item(): IGlossaryItem<any> {
    return $app.item(this.glossary!, this.value!) || {
      code: '??',
      color: 'error',
      icon: 'fa-question-circle',
      label: 'system.NoData'
    };
  }
}
